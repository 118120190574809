
.tps-site-footer {
    background-color: $color-brand-beige;
    padding: rem-calc(30) rem-calc(10);

    @include breakpoint(medium) {
        padding-right: rem-calc(5);
        padding-left: rem-calc(5);
    }

    @include breakpoint(tablet) {
        padding-bottom: rem-calc(45);
        padding-top: rem-calc(45);
    }

    @include breakpoint(large) {
        padding-bottom: rem-calc(65);
        padding-top: rem-calc(65);
    }

    &-menu {
        &-list {
            margin: 0;

            @include breakpoint(tablet) {
                display: flex;
            }

            &-item {
                line-height: 1.125;
                margin-bottom: rem-calc(28);

                @include breakpoint(tablet) {
                    flex: 0 1 auto;
                    margin-right: rem-calc(52);
                    margin-bottom: 0;
                }

                &:last-child {
                    @include breakpoint(tablet) {
                        margin-right: 0;
                    }
                }
                //&-link {}
            }
        }
    }

    a {
        color: $color-black;
        text-decoration: none;

        &:visited,
        &:active {
            color: $color-black;
        }

        &:hover,
        &:focus {
            color: rgba($color-black, 0.6);
        }
    }

    &-top {
        padding-bottom: rem-calc(10);

        @include breakpoint(tablet) {
            padding-bottom: rem-calc(42.5);
        }
    }

    &-bottom {
        border-top: rem-calc(1) solid $color-black;
        padding-top: rem-calc(25);

        @include breakpoint(tablet) {
            padding-top: rem-calc(44);
        }
    }

    &-copyright {
        color: rgba($color-black, 0.6);
        font-size: rem-calc(12);

        @include breakpoint(medium down) {
            margin-top: rem-calc(15);
        }

    }
}
