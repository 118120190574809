.tps-bulletins {
    &-links {
        &, .tps-page-section-column & {
            margin: 0 0 rem-calc(50) 0;
        }

        li {
            &:not(:last-child) {
                margin-bottom: rem-calc(10);
            }
        }

        a {
            @include button-arrow-forward($color-brand-orange, rgba($color-brand-orange, 0.6));
            color: $black !important;
        }
    }

    &-category {
        margin-bottom: rem-calc(50);

        &-header {
            margin-bottom: rem-calc(10);
            font-size: rem-calc(20);
            color: $color-brand-gray-dark;
        }
    }
    // Override styles for the default accordion style.
    .tps-accordion {
        &-item {
            &.is-active {
                .tps-accordion-title {
                    border-bottom-color: transparent;

                    &::before {
                        color: inherit;
                    }
                }
            }
        }

        &-title {
            padding: rem-calc(35) 0;
            background-color: transparent;
        }

        &-content {
            padding: 0;
            font-size: rem-calc(16);
        }
    }
}
