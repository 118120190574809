

.tps-modal-cookies-settings {

    &-tab {

        &-header {
            font-size: rem-calc(18);
            margin-bottom: rem-calc(20);
            display: flex;

            &-info {
                color: $color-brand-gray-dark;
                flex: 1 0 auto;
                text-align: right;
                font-size: rem-calc(16);
            }
        }

        &-content {
        }

        &-cookielist {
            border-top: rem-calc(1) solid $color-brand-gray-dark;
            margin-top: rem-calc(20);
            padding-top: rem-calc(20);
        }
    }

    &-confirm {
        padding-left: 0;
        margin-bottom: 0;
    }
}
