
.tps-site-off-canvas {
    background-color: $color-black;
    color: $color-white;

    &-inner {
        padding: rem-calc(20);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        min-height: 100%;

        @include breakpoint(tablet) {
            padding-left: rem-calc(50);
            padding-right: rem-calc(50);
        }
    }

    &-close {
        top: rem-calc(40);
        right: rem-calc(20);
        color: $color-white;

        &:hover,
        &:active,
        &:visited,
        &:focus {
            color: $color-white;
        }
    }

    &-signout {
        color: $color-white;
        position: absolute;
        top: rem-calc(48);

        &:hover, &:focus, &:focus-visible, &:visited, &:active {
            color: rgba($color-white, 0.6);
        }
    }

    &-navigation {
        flex: 1 0 auto;
    }

    &-navigation-utility {
        flex: 0 1 auto;
    }
}
