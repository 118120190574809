
.tps-auth-form {
    &-wrapper {
        @include add-flex-grow-column();
        max-width: rem-calc(335);

        &--big {
            max-width: rem-calc(750);
        }
    }

    &-headline,
    &-subheadline {
        flex: 0 0 auto;
        font-size: rem-calc(30);
        line-height: 1.067;
        margin-bottom: rem-calc(12);
        padding-bottom: rem-calc(12);
    }

    &-headline {
        position: relative;

        &:after {
            background-color: $color-black;
            content: "";
            display: block;
            height: rem-calc(2);
            width: rem-calc(30);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &-form {
        flex: 1 0 auto;
    }

    &-content {

        &-intro,
        &-footer {
            flex: 0 0 auto;

            & > *:last-child {
                margin-bottom: 0;
            }
        }

        &-intro {
            line-height: 1.125;
            margin-bottom: rem-calc(35);

            a {
                color: $color-blue-denim;
            }
        }

        &-footer {
            margin-top: rem-calc(50);
            font-size: rem-calc(14);
            line-height: 1.14;
        }
    }
}
