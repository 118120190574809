@mixin button-arrow-forward($color, $hoverColor) {
    $fillColor: str-replace('' + $color, '#', '%23');
    $hoverFillColor: str-replace('' + $hoverColor, '#', '%23');

    &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 16' height='1em' role='img' aria-label='arrowRight'%3E%3Cpath d='M6 2 4.95 3.05l4.2 4.2H0v1.5h9.15l-4.2 4.2L6 14l6-6z' fill='#{$fillColor}'%3E%3C/path%3E%3C/svg%3E");
        display: inline-block;
        margin-left: rem-calc(10);
        vertical-align: middle;
    }

    &:hover {
        &::after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 16' height='1em' role='img' aria-label='arrowRight'%3E%3Cpath d='M6 2 4.95 3.05l4.2 4.2H0v1.5h9.15l-4.2 4.2L6 14l6-6z' fill='#{$hoverFillColor}'%3E%3C/path%3E%3C/svg%3E");
        }
    }
}

@mixin button-arrow-reverse($color, $hoverColor) {
    $fillColor: str-replace('' + $color, '#', '%23');
    $hoverFillColor: str-replace('' + $hoverColor, '#', '%23');

    &::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 13' height='0.8125rem' role='img' aria-label='arrowLeft'%3E%3Cpath d='M6 0.970551L7.05 2.02055L2.85 6.22055H12V7.72055H2.85L7.05 11.9206L6 12.9706L0 6.97055L6 0.970551Z' fill='#{$fillColor}'%3E%3C/path%3E%3C/svg%3E");
        display: inline-block;
        margin-right: rem-calc(10);
        vertical-align: middle;
    }

    &:hover {
        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 13' height='0.8125rem' role='img' aria-label='arrowLeft'%3E%3Cpath d='M6 0.970551L7.05 2.02055L2.85 6.22055H12V7.72055H2.85L7.05 11.9206L6 12.9706L0 6.97055L6 0.970551Z' fill='#{$hoverFillColor}'%3E%3C/path%3E%3C/svg%3E");
        }
    }
}


@mixin add-flex-grow-column() {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
}
