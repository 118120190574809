

.tps-pagination {


    &-list {
        display: flex;
        align-items: center;

        @include breakpoint(tablet down) {
            justify-content: center;
        }

        &-item {
            margin-left: rem-calc(2);

            &:first-child {
                margin-left: 0;
            }

            &-ctrl {
                display: block;
                padding: rem-calc(8);

                &.next {
                    .tps-icon {
                        content: url('data:image/svg+xml;utf8,<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 0L4.625 0.875L8.125 4.375H0.5V5.625H8.125L4.625 9.125L5.5 10L10.5 5L5.5 0Z" fill="%23FF7500"/></svg>');
                    }
                }

                &.previous {
                    .tps-icon {
                        content: url('data:image/svg+xml;utf8,<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 0L6.375 0.875L2.875 4.375H10.5V5.625H2.875L6.375 9.125L5.5 10L0.5 5L5.5 0Z" fill="%23FF7500"/></svg>');
                    }
                }
            }
        }
    }

    &-pagesize {

        &-form {
            margin: 0 rem-calc(5);
        }

        &-control {
            display: flex;
            align-items: center;

            label,
            select {
                margin: 0 rem-calc(5);
            }

            label {
                flex: 0 0 auto;
            }

            select {
                flex: 0 1 auto;
                text-align: right;
                line-height: rem-calc(32);
                height: rem-calc(32);
            }
        }
    }
}
