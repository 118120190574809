

.tps-page-section {
    flex: 0 0 auto;

    &.section-bg-beige {
        padding: rem-calc(10);
        padding-top: 0;

        @include breakpoint(tablet) {
            padding: rem-calc(15);
            padding-top: 0;
        }

        @include breakpoint(xxlarge) {
            padding: rem-calc(33);
            padding-top: 0;
        }
    }

    &-inner {
        position: relative;
        max-width: rem-calc(1350);
        margin: auto;

        .section-bg-beige & {
            background-color: $color-brand-beige;
        }

        .padding-top & {
            padding-top: rem-calc(45);
        }

        .padding-bottom & {
            padding-bottom: rem-calc(45);
        }
    }

    .grid-container {
        position: relative;
        z-index: 1;
    }

    &-column {

        
    }
    //
    &.section-stretch {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;

        & > .tps-page-section-inner {
            flex: 1 0 auto;
            @include add-flex-grow-column();

            & > .grid-container {
                flex: 1 0 auto;
                @include add-flex-grow-column();

                & > .grid-x {
                    flex: 1 0 auto;

                    & > .cell {
                        @include add-flex-grow-column();
                    }
                }
            }
        }
    }
}
