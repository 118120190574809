
html,
body {
    min-height: 100%;
    height: 100%;
}

.tps-site {

    &-bypass-link {
        background-color: $color-black;
        color: $color-white;
        height: 0;
        width: 0;
        margin: 0;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;

        &:focus {
            color: $color-white;
            height: auto;
            width: auto;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &-header,
    &-footer {
        flex: 0 0 auto;
    }

    &-content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;

        // body.tps-header-sticky & {}
    }
}
