
.tps-page-section-column {


    a:not(.accordion-title):not(.button):not(.button-link) {
        color: $color-beige-shadow;

        &:hover, &:hover {
            color: scale-color($color-beige-shadow, $lightness: -14%);
        }

        .section-bg-beige & {
            color: $color-black;

            &:hover, &:hover {
                color: scale-color($color-black, $lightness: -14%);
            }
        }
    }
    // styles for rich text elements created by
    // the page builder components (widgets/template properties)


    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4 {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(35);

        &:first-child {
            margin-top: 0;
        }

        .highlight {
            color: $color-brand-gray-dark;
            display: block;
        }
    }

    h5, .h5,
    h6, .h6 {
        margin-top: rem-calc(25);
        margin-bottom: rem-calc(20);

        &:first-child {
            margin-top: 0;
        }
    }

    p,
    ol,
    ul {
        margin-bottom: rem-calc(20);
    }

    ol:not(.no-bullet),
    ul:not(.no-bullet) {
        list-style: none;
        margin-left: 0;

        li {
            line-height: 1.125;
            margin-bottom: rem-calc(12);
            padding-left: rem-calc(25);
            position: relative;

            &:before {
                position: absolute;
                left: 0;
            }
        }
    }

    ol:not(.no-bullet) {
        counter-reset: tps-counter;

        li {
            &:before {
                counter-increment: tps-counter;
                content: counters(tps-counter, ".") ".";
                color: $color-brand-orange;
                text-align: right;
                margin-right: rem-calc(-40);
                white-space: nowrap;

                .section-bg-beige & {
                    color: $color-black;
                }
            }
        }
    }

    ul:not(.no-bullet) {

        li {

            &:before {
                content: url('data:image/svg+xml;utf8,<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6.5" cy="7" r="5.5" stroke="%23FF7500" stroke-width="1.5"/></svg>');
                top: rem-calc(2);
                left: 0;

                .section-bg-beige & {
                    content: url('data:image/svg+xml;utf8,<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6.5" cy="7" r="5.5" stroke="%23000000" stroke-width="1.5"/></svg>');
                }
            }
        }
    }
}
