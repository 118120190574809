.tps-accordion {
    background-color: transparent;

    &-item {
        border-bottom: rem-calc(2) solid $color-gray-edward;
    }

    &-title {
        background: $color-beige-cararra;
        font-family: $font-family-sans;
        font-size: rem-calc(26);
        font-weight: $font-weight-medium;
        padding: rem-calc(35) rem-calc(20);
        color: $black;
        text-decoration: none;

        .is-active > & {
            border-bottom: rem-calc(2) solid $color-gray-edward;
        }

        &:hover, &:focus {
            background-color: $color-beige-cararra;
        }

        &:before {
            right: rem-calc(20);
            font-size: rem-calc(30);
            font-weight: $font-weight-light;
            font-family: $font-icons;
            margin-top: rem-calc(-15);

            .is-active > & {
                color: $color-orange-flush;
            }
        }
    }

    &-content {
        background-color: transparent;
        padding: 0 rem-calc(40) 0 rem-calc(20);
        font-size: rem-calc(20);

        strong {
            font-weight: 700;
        }

        &-inner {
            padding: rem-calc(35) 0;
        }

        .tps-accordion {
            &-item {
                &:last-child {
                    border-bottom: 0;
                }

                &.is-active > .tps-accordion-title {
                    border-bottom: 0;
                }
            }

            &-title {
                background: transparent;

                &:hover, &:focus {
                    background-color: transparent;
                }
            }

            &-content {
                .tps-accordion-content-inner {
                    padding-top: 0;
                }
            }
        }
    }
}
