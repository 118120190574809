html, body {
    height: 100%; // for sticky footer
    scroll-behavior: smooth;
}

html {
    font-size: 100%;
}

body {
    display: flex; // for sticky footer
    flex-direction: column; // for sticky footer
    font-size: rem-calc(16);
}
