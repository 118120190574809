
.tps-billboard {
    padding: 0 rem-calc(10);

    @include breakpoint(tablet) {
        padding: 0 rem-calc(15);
    }

    @include breakpoint(xxlarge) {
        padding: 0 rem-calc(30);
    }

    &-inner {
        background-color: $color-brand-beige;
        position: relative;
        max-width: rem-calc(1350);
        margin: 0 auto;

        .tps-billboard-color-white & {
            background-color: $color-gray-bunker;
        }

        .tps-billboard-background-white:not(.tps-billboard-color-white) & {
            background-color: $color-white;
        }

        .grid-container {
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: rem-calc(335);
            padding-top: rem-calc(45);
            padding-bottom: rem-calc(37.5);
            display: flex;
            flex-direction: column;

            @include breakpoint(medium) {
                min-height: rem-calc(235);
            }

            @include breakpoint(tablet) {
                padding-top: rem-calc(75);
            }

            .tps-billboard-tall & {
                min-height: rem-calc(530);

                @include breakpoint(medium) {
                    min-height: rem-calc(530);
                }
            }
        }
    }

    &-grid {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100%;
        //outline: 1px solid red;
        max-width: rem-calc(385);

        &.tps-billboard-align-ctas-right {
            max-width: 100%;

            @include breakpoint(tablet) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &-content {
        flex: 1 0 auto;
        max-width: rem-calc(385);
    }

    &-headline,
    &-subheadline {
        font-size: rem-calc(30);
        line-height: 1.067;
        margin-bottom: rem-calc(25);

        .tps-billboard-color-white & {
            color: $color-white;
        }
    }

    &-subheadline {
        position: relative;

        &:before {
            background-color: $color-black;
            content: "";
            display: block;
            margin-top: rem-calc(-12.5);
            position: absolute;
            height: rem-calc(2);
            width: rem-calc(30);
            top: 0;
            left: 0;
        }
    }

    &-text {
        line-height: 1.067;

        & > * {
            line-height: 1.067;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .tps-billboard-color-white & {
            color: $color-white;
        }
    }

    &-actions {
        margin-top: rem-calc(30);
        flex: 0 1 auto;

        .tps-billboard-align-ctas-right & {
            @include breakpoint(tablet) {
                margin-top: 0;
            }
        }

        .button {
            margin-bottom: rem-calc(11);
            // force the line break on mobile
            min-width: 51%;

            @include breakpoint(tablet) {
                margin-right: rem-calc(18);
                margin-bottom: 0;
                min-width: 0;
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}
