
.tps-site-off-canvas-navigation {
    padding-top: rem-calc(90);

    @include breakpoint(tablet) {
        padding-top: rem-calc(120);
    }

    &-list {
        margin: 0;

        &-item {
            margin-bottom: rem-calc(30);

            &-link {
                color: $color-white;
                font-size: rem-calc(30);
                font-weight: $font-weight-light;
                text-decoration: none;

                &:hover,
                &:visited,
                &:focus,
                &:active {
                    color: $color-white;
                }
            }
        }
    }

    &-utility {
        &-list {
            margin: 0;

            &-item {
                margin-bottom: rem-calc(20);

                &-link {
                    color: $color-white;
                    font-size: rem-calc(16);
                    font-weight: $font-weight-light;
                    text-decoration: none;

                    &:hover,
                    &:visited,
                    &:focus,
                    &:active {
                        color: $color-white;
                    }
                }

                .tps-modal-language-toggle-ctl {
                    font-size: rem-calc(16);
                    font-weight: $font-weight-light;

                    .tps-modal-language-toggle-ctl-label {
                        display: block;

                        @include breakpoint(425px) {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }

                    .tps-modal-language-toggle-ctl-action {
                        color: $color-white;
                        text-decoration: none;

                        &:after {
                            content: "";
                        }
                    }
                }
            }
        }
    }
}
