
.tps-cookies-disclaimer {
    background-color: rgba($black, 0.85);
    margin: 0;
    padding: rem-calc(25) rem-calc(16) rem-calc(16);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $reveal-zindex + 1;
    color: $white;
    font-weight: $font-weight-light;
    font-size: rem-calc(15);
    line-height: 1.5;

    a {
        color: $white;

        &:focus {
            outline: rem-calc(2) solid $white;
        }
    }

    &-inner {
        position: relative;
    }

    &-content {
        margin-bottom: rem-calc(20);
    }

    &-close-button {
        font-size: rem-calc(20);
        line-height: 1;
        position: absolute;
        top: rem-calc(-16);
        right: rem-calc(0);

        &:focus {
            outline: rem-calc(2) solid $white;
        }
    }
}
