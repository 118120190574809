

.tps-tabs {

    &-list {

        &-title {

            &-link {
                background-color: $tab-background;
                color: $tab-color;
                display: block;
                font-size: $tab-item-font-size;
                padding: $tab-item-padding;
                text-decoration: none;

                &:hover {
                    background-color: $tab-item-background-hover;
                }

                .vertical & {
                    padding-left: 0;
                }

                .is-active > & {
                    background-color: $tab-background-active;
                    color: $tab-active-color;
                }

                &-label {
                    display: inline-block;
                    position: relative;
                    padding-bottom: rem-calc(3);

                    .is-active & {

                        &:after {
                            border-bottom: rem-calc(2) solid $color-orange-clementine;
                            content: "";
                            display: block;
                            position: absolute;
                            height: 0;
                            left: 0;
                            bottom: rem-calc(-1);
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    &-content {

        &-panel {
            background-color: $tab-content-background;
            border: $tab-content-border;
            color: $tab-content-color;
            padding: $tab-content-padding;
        }
    }
}
