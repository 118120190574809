
// remove styles for anchor buttons
a.button {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.button,
button.button,
[type="submit"] {
    @include button-arrow-forward($color-brand-orange, rgba($color-brand-orange, 0.6));
    background-color: $color-black;
    padding-right: rem-calc(45);

    &:active, &:hover, &:focus {
        background-color: rgba($color-black, 0.6);
    }

    &[disabled], &.disabled {
        background-color: $color-black;

        &:active, &:hover, &:focus {
            background-color: $color-black;
        }
    }

    &.button-light {
        background-color: $color-brand-beige;
        color: $color-black;

        .section-bg-beige &,
        .tps-billboard-beige & {
            background-color: $color-white;

            &:active, &:hover, &:focus {
                background-color: $color-gray-nobel;
            }

            &[disabled], &.disabled {
                background-color: $color-white;

                &:active, &:hover, &:focus {
                    background-color: $color-white;
                }
            }
        }

        &:active, &:hover, &:focus {
            background-color: rgba($color-brand-beige, 0.6);
            color: $color-black;
        }

        &[disabled], &.disabled {
            background-color: $color-brand-beige;

            &:active, &:hover, &:focus {
                background-color: $color-brand-beige;
            }
        }
    }

    &.loading,
    &.submitting {
        &:after {
            animation: fa-spin 2s linear infinite;
            color: $color-brand-orange;
            content: "\f021";
            font-family: $font-icons;
        }

        &[disabled], &.disabled {
            opacity: 0.75;
        }
    }


    &#W9Submit {
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(13);
    }
}

.button-link {
    border: $button-border;
    cursor: pointer;
    margin: $button-margin;
    padding: $button-padding;
    text-decoration: none;

    // add the arrow icon
    @include button-arrow-forward($color-brand-orange, rgba($color-brand-orange, 0.6));

    &.reverse {
        @include button-arrow-reverse($color-brand-orange, rgba($color-brand-orange, 0.6));

        &:after {
            content: none;
        }
    }
}

