
.tps-modal {
    padding: rem-calc(15);

    @include breakpoint(tablet) {
        padding: rem-calc(30);
    }

    &.has-header {
        padding-top: 0;
    }

    &.has-actions {
        padding-bottom: 0;
    }

    &.tps-modal-full {
        top: 0 !important;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: none;
        height: 100%;
        min-height: 100%;
        margin-left: 0;
        border: 0;
        border-radius: 0;
    }

    &[aria-hidden="false"] {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }

    .grid-container {
        width: 100%;
    }

    &-close {
        font-size: rem-calc(25);
        right: rem-calc(24);
        top: rem-calc(20);
    }

    &-header {
        color: $color-black;
        display: flex;
        align-items: baseline;
        line-height: 1.067;
        padding: rem-calc(20) 0 rem-calc(30);

        @include breakpoint(tablet) {
            padding-top: rem-calc(30);
        }

        &-sitelabel {
            font-size: rem-calc(30);
        }

        &-text {
            padding-left: rem-calc(20);
        }
    }

    &-content {
        font-size: rem-calc(16);
        padding: rem-calc(20) 0;

        *:last-child {
            margin-bottom: 0;
        }
    }

    &-actions {
        padding: rem-calc(30) 0 rem-calc(15);

        @include breakpoint(tablet) {
            padding-bottom: rem-calc(30);
        }

        .button, .button-link {
            margin-bottom: 0;
        }
    }


    &-cookies-settings {
        width: rem-calc(750);

        &-cookielist {
            &-item {
                border-top: rem-calc(1) solid $light-gray;
                padding-top: rem-calc(10);
                padding-bottom: rem-calc(10);
            }
        }
    }

    &-language-toggle {
        &-region {
            &-headline {
                font-size: rem-calc(30);
                line-height: 1.067;
                margin-bottom: rem-calc(32);
            }

            &-list {

                &-item {
                    margin-bottom: rem-calc(15);
                }
            }
        }
    }
}
