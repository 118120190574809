
@font-face {
    src: url('/assets/webfonts/polestar-unica/light.woff2') format('woff2'),
        url('/assets/webfonts/polestar-unica/light.woff') format('woff');
    font-family: "Polestar Unica";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    src: url('/assets/webfonts/polestar-unica/regular.woff2') format('woff2'),
        url('/assets/webfonts/polestar-unica/regular.woff') format('woff');
    font-family: "Polestar Unica";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    src: url('/assets/webfonts/polestar-unica/bold.woff2') format('woff2'),
        url('/assets/webfonts/polestar-unica/bold.woff') format('woff');
    font-family: "Polestar Unica";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-family-sans: "Polestar Unica", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: 'Times New Roman', serif;

$font-icons: "Font Awesome 5 Pro";

// ## FOUNDATION ##

$body-font-color: $color-black;
$body-font-family: $font-family-sans;

$global-weight-normal: $font-weight-regular;
$global-weight-bold: $font-weight-bold;

