
.tps-featurette {
    &-main-text {
        font-size: rem-calc(30);
        line-height: 1.067;
        opacity: 0;
        padding-top: rem-calc(40);
        transition: all 0.3s ease-in-out 0.15s;

        &.active {
            opacity: 1;
            padding-top: 0;
            transition: all 0.5s ease-in-out;
        }

        & > * {
            line-height: 1.067;
        }
    }

    &-grid {
        display: flex;
        flex-direction: row;

        &-column {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            padding-left: rem-calc(5);

            @include breakpoint(medium) {
                padding-left: rem-calc(15);
            }

            @include breakpoint(tablet) {
                padding-left: 17%;
            }

            &:first-child {
                padding-left: 0;
                padding-right: rem-calc(5);

                @include breakpoint(medium) {
                    padding-right: rem-calc(15);
                }

                @include breakpoint(tablet) {
                    padding-right: 0;
                    padding-left: 17%;
                }
            }
        }
    }

    &-item {
        opacity: 0;
        flex: 1 1 auto;
        margin-top: rem-calc(7.5);
        padding-top: rem-calc(47.5);
        position: relative;
        transition: all 0.3s ease-in-out 0.15s;

        @include breakpoint(tablet) {
            margin-top: 0;
            margin-bottom: rem-calc(15);
            padding-top: rem-calc(55);
        }

        &:before {
            border-top: rem-calc(1) solid $color-black;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
        }

        &.active {
            padding-top: rem-calc(7.5);
            opacity: 1;
            transition: all 0.3s ease-in-out 0.15s;

            &.disabled {
                opacity: 0.5;
            }

            @include breakpoint(tablet) {
                padding-top: rem-calc(15);
            }

            &:before {
                transition: width 0.35s ease-in-out 0.25s;
                width: 100%;
            }
        }

        &.disabed {
            opacity: 0.5;
        }

        &-inner {
            font-size: rem-calc(17);
            line-height: 1.2;
            min-height: rem-calc(70);

            @include breakpoint(tablet) {
                font-size: rem-calc(20);
            }

            & > * {
                line-height: 1.2;
            }
        }
    }
}
