.tps-icon-cards {
    $gap-tablet: 16px;
    $gap-large: 30px;
    margin-bottom: rem-calc(-16);

    @include breakpoint(tablet) {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem-calc(-$gap-tablet/2);
        margin-right: rem-calc(-$gap-tablet/2);
    }

    @include breakpoint(large) {
        margin-left: rem-calc(-$gap-large/2);
        margin-right: rem-calc(-$gap-large/2);
    }

    &-item {
        display: block;
        margin-bottom: rem-calc(16);
        padding: rem-calc(27) rem-calc(20);
        background: $white;
        text-decoration: none;

        @include breakpoint(tablet) {
            margin-left: rem-calc($gap-tablet/2);
            margin-right: rem-calc($gap-tablet/2);
            padding: rem-calc(27) rem-calc(30);
            width: calc(50% - #{rem-calc($gap-tablet)});
        }

        @include breakpoint(large) {
            margin-left: rem-calc($gap-large/2);
            margin-right: rem-calc($gap-large/2);
            width: calc(25% - #{rem-calc($gap-large)});
        }

        &--disabled {
            opacity: 0.2;
            pointer-events: none;
        }

        &-image {
            display: block;
            margin-bottom: rem-calc(20);
            width: auto;
            height: rem-calc(45);
        }

        &-title {
            margin-bottom: rem-calc(20);
            font-size: rem-calc(20);
            line-height: 1.2;
            color: $black;

            &::before {
                content: '';
                display: block;
                margin-bottom: rem-calc(20);
                width: rem-calc(30);
                height: rem-calc(2);
                background: $black;
            }
        }

        &-description {
            font-size: rem-calc(16);
            line-height: 1.12;
            color: $black;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }
}
