

.tps-history-table-spiffs {

    &-header {

        // sorting control on spiffs table
        &-ctrl {

            .tps-icon {
                margin-left: rem-calc(5)
            }
        }
    }

}
