
.tps-site-header {
    background-color: $color-white;

    @include breakpoint(tablet) {
        padding: rem-calc(14) 0;
    }

    &-menu-toggle {
        cursor: pointer;
    }

    &-button {
        display: inline-block;
        padding: rem-calc(5);
        text-decoration: none;
        vertical-align: middle;
    }

    &-user {
        margin: 0 rem-calc(10);
        &-avatar {
            background-color: $color-brand-beige;
            border-radius: rem-calc(15);
            display: block;
            line-height: rem-calc(30);
            overflow: hidden;
            text-align: center;
            text-transform: uppercase;
            height: rem-calc(30);
            width: rem-calc(30);
        }
    }


    &-logo {
        display: block;
        text-decoration: none;
        padding: rem-calc(7) 0 rem-calc(3);
        line-height: 1.25;

        &-text {
            font-size: rem-calc(30);

            &.highlighted {
                color: $color-brand-gray-dark;
            }
        }
    }
}
