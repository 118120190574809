

table {
    font-size: rem-calc(16);

    td, th {
        border-bottom-width: rem-calc(1);
        border-bottom-style: solid;
        line-height: 1.25;
    }

    tbody {

        td {
            border-bottom-color: $color-brand-beige;
            font-weight: $font-weight-light;

            &.tps-table-cell-nowrap {
                white-space: nowrap;
            }
        }
    }
}
