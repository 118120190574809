
// Beige
$color-beige-cararra: #ECECE7;
$color-beige-straw: #D3BC8D;
$color-beige-shadow: #866D4B;

// Oranges
$color-orange-flush: #ff7500;
$color-orange-clementine: #F06E00;

// Grays
$color-gray-rolling-stone: #75787B;
$color-gray-edward: #B1B3B3;
$color-gray-black-haze: #F3F4F4;
$color-gray-bunker: #101820;
$color-gray-cod: #141414;
$color-gray-nobel: #B3B3B3;
$color-gray-storm-dust: #6A6A68;
$color-gray-boulder: #7d7d7d;

// Blues
$color-blue-denim: #1C6BBA;

// Reds
$color-red-alert: #cc4b37;

$color-brand-orange: $color-orange-flush;
$color-brand-beige: $color-beige-cararra;
$color-brand-gray-dark: $color-gray-rolling-stone;
$color-brand-gray-medium: $color-gray-edward;
$color-brand-gray-light: $color-gray-black-haze;

// B&W absolutes
$color-black-absolute: #000000;
$color-white-absolute: #FFFFFF;

// ## Foundation ##

// B&W
$color-black: $color-black-absolute;
$color-white: $color-white-absolute;

$black: $color-black;
$white: $color-white;

$dark-gray: $color-gray-rolling-stone;
$medium-gray: $color-brand-gray-medium; 
$light-gray: $color-brand-gray-light;
